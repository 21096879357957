// tslint:disable:max-line-length
const useCases = `
# Area Hierarchy
## Maponics Neighborhood Boundaries
Maponics delineates the geographies that matter most to customers in the areas where people live, work, and socialize. Whether you need a simple lookup of neighborhood names or want a more meaningful way to organize and present map data, this dataset is considered the industry standard by web and mobile organizations across the globe.

## Maponics Residential Boundaries
Maponics Residential, provides data about the residential areas in and around metros. These boundaries delineate neighborhoods with houses, condos, and apartment complexes, and exclude those with commercial, industrial, or public spaces.

## Maponics Metro Boundaries
Metros are densely populated locations are comprised of cities, towns and rural areas that are socio-economically linked to urban centers. They include urban areas in which population concentration is greater than 50,000 and population density is greater than 1,000 people per square mile.

# Census Hierarchy
## Incorporated Places
Places always are within a single state or equivalent entity, but may extend across county and county subdivision boundaries. An incorporated place usually is a city, town, village, or borough, but can have other legal descriptions.

## County Subdivisions
County Subdivisions are the primary divisions of counties and equivalent entities. They include census county divisions, census subareas, minor civil divisions, and unorganized territories and can be classified as either legal or statistical. *NOTE: ATTOM has chosen to use either Census Place OR County Subdivision boundaries in certain areas where the geography type best fits the local understanding and designation of geographies.`;
// tslint:enable:max-line-length

export default useCases;

// tslint:disable:max-line-length
const useCases = `
# New Standards become reality with Sensorberg

Management efficiency will soon become the key to success. With our digitalization modules You can optimize processes between residents, administration and service providers.

In the future all residents will be closely bound up with their flats. Numerous simplifications in everyday life create additional comfort conditions that soon no resident would want to miss. The intuitive and user-friendly app from Sensorberg integrates all the relevant settings, data and services of the house.

The transparent platform also serves for an uncomplicated exchange between neighbors. At the same time, it stands for fast and direct communication with the administration as well as better access to problem solving channels.

The digital hub between residents, house and administration offers a lot of possibilities to connect further functions and partners. This is where new long-term business models can be developed. Furthermore there is also much potential to integrate the services of already existing companies. Examples of service partners could be any delivery services, cleaning companies and necessary qualified skilled workers.

Overall, data analysis will help you recognize trends and turn them into profitable business models faster.
`;
// tslint:enable:max-line-length

export default useCases;

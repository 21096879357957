/** Types */
type Email = string;


const toParamsString = (params = {}) => {
  return Object.keys(params).map((key) => (
    // @ts-ignore
    `${key}=${encodeURIComponent(params[key])}`
  )).join('&');

};

const createMailtoLink = (email: string, params?: object) => {
  let link = `mailto:${email}`;
  if (params) {
    link += `?${toParamsString(params)}`;
  }
  return link;
};

export {
  createMailtoLink
};

/** Imports */
import React, {
  Component,
  ContextType
} from 'react';

import { RedocRawOptions, RedocStandalone } from 'redoc';

import { Spec as Swagger } from 'swagger-schema-official';

import {
  Container,
  PrivateField,
} from 'app/components';

import {
  SessionContext,
} from 'app/contexts';

import styles from './endpoints.module.scss';


/** Types */
type Props = {
  swagger: Swagger;
};

/** Constants */
// tslint:disable:max-line-length
const OPTIONS: RedocRawOptions = {
  theme: {
    spacing: {
      unit: 5,
      sectionHorizontal: ({ spacing }) => spacing.unit * 8,
      sectionVertical: ({ spacing }) => spacing.unit * 8,
    },
    breakpoints: {
      small: '50rem',
      medium: '85rem',
      large: '105rem',
    },
    colors: {
      tonalOffset: 0.3,
      primary: {
        main: '#8796aa',
      },
      success: {
        main: '#00aa13',
      },
      warning: {
        main: '#d4ad03',
        contrastText: '#ffffff',
      },
      error: {
        main: '#e53935',
      },
      text: {
        primary: '#616b7a',
      },
      border: {
        dark: '#000000',
        light: '#ffffff',
      },
      responses: {
        success: {
          color: ({ colors }) => colors.success.main,
        },
        error: {
          color: ({ colors }) => colors.error.main,
        },
        redirect: {
          color: '#ffa500',
        },
        info: {
          color: '#87ceeb',
        },
      },
      http: {
        get: '#6bbd5b',
        post: '#248fb2',
        put: '#9b708b',
        options: '#d3ca12',
        patch: '#e09d43',
        delete: '#e27a7a',
        basic: '#999',
        link: '#31bbb6',
        head: '#c167e4',
      },
    },
    schema: {
      defaultDetailsWidth: '75%',
      typeNameColor: (theme) => theme.colors.text.secondary,
      typeTitleColor: (theme) => theme.schema.typeNameColor,
      requireLabelColor: (theme) => theme.colors.error.main,
      labelsTextSize: '0.9em',
      nestingSpacing: '1em',
      arrow: {
        size: '1.1em',
        color: (theme) => theme.colors.text.secondary,
      },
    },
    typography: {
      fontSize: '14px',
      lineHeight: '1.5em',
      fontWeightRegular: '400',
      fontWeightBold: '600',
      fontWeightLight: '300',
      fontFamily: 'metropolis, sans-serif',
      smoothing: 'antialiased',
      optimizeSpeed: true,
      headings: {
        fontFamily: 'metropolis, sans-serif',
        fontWeight: '400',
      },
      code: {
        fontSize: '13px',
        fontFamily: 'Courier, monospace',
        lineHeight: ({ typography }) => typography.lineHeight,
        fontWeight: ({ typography }) => typography.fontWeightRegular,
        color: '#e53935',
        wrap: false,
      },
      links: {
        color: ({ colors }) => colors.primary.main,
        visited: ({ typography }) => typography.links.color,
      },
    },
    sidebar: {
      width: '260px',
      backgroundColor: '#13161a',
      textColor: '#616b7a',
      groupItems: {
        textTransform: 'uppercase',
      },
      level1Items: {
        textTransform: 'none',
      },
      arrow: {
        size: '1.5em',
        color: (theme) => theme.sidebar.textColor,
      },
    },
    logo: {
      maxHeight: ({ sidebar }) => sidebar.width,
      maxWidth: ({ sidebar }) => sidebar.width,
      // gutter: '2px',
    },
    rightPanel: {
      backgroundColor: '#13161a',
      width: '40%',
      textColor: '#ffffff',
    },
    codeBlock: {
      backgroundColor: '#0d0e10',
    },
  },
  scrollYOffset: 60,
  noAutoAuth: true,
  menuToggle: true,
};
// tslint:enable:max-line-length


class Endpoints extends Component<Props> {

  static contextType = SessionContext;

  context!: ContextType<typeof SessionContext>;

  public render() {

    const authToken = this.context ? this.context.authToken : undefined;

    return (
      <>
        <Container>
          {authToken && <PrivateField value={authToken}/>}
        </Container>

        <div className={styles.redoc}>
          <RedocStandalone
            spec={this.props.swagger}
            options={OPTIONS}
          />
        </div>
      </>
    );
  }
}


export {
  Endpoints
};

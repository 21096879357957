import description from './description';
import useCases from './use-cases';

export const platformOfTrustBrokerApi = {
  uuid: 'd09490e8-9be0-43c5-bf1b-ac7f52a91428',
  name: 'POT Broker Api',
  category: 'Property market',
  url: 'platform-of-trust-broker-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: 'Platform Of Trust',
};

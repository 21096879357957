import description from './description';
import useCases from './use-cases';

export const attomDataCommunityApi = {
  uuid: '83c4fcd3-4671-4dd7-bc29-cafe5303dffa',
  name: 'Attom Community Api',
  category: 'Property market',
  url: 'attom-data-community-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: 'Attom Data',
};

// tslint:disable:max-line-length
const description = `
# What is Infabode

[![N|Solid](https://i.imgur.com/e2y8qfI.png)](https://www.infabode.com)


Powered by information and technology, Infabode connects users with information from the real estate industry, on one customisable online platform.

Targeted at anyone looking to access real estate research and insights, Infabode is increasing the efficiency with which key information is gathered and distributed.

Infabode breaks the global industry down through an intricate hub structure which means that we can offer highly targeted content and advertising opportunities.

With over 15,000 professionals Infabode is quickly becoming
one of the largest online real estate communities.

# Industry Sectors

Infabode allows members to choose from the real estate sectors they are interested in. The platform connects them with information and relevant adverts from the following markets:

- Office
- Retail
- Investment Vehicles
- Residential
- Capital Markets & Economics
- Industrial
- Student Halls
- Hotels
- SustainabilityImport a HTML file and watch it magically convert to Markdown
- Property Technology
- Mixed Use
- Logistics
- Accounting
- Alternative sectors
- Legal
- Debt
- Rural



# Online rates

Discounts available to organisations investing in multiple marketing products and larger discounts offered on 6 and 12 month contracts.

| 3-month contracts | GOLD | SILVER | BRONZE |
| ------ | ------ | ------ | ------ |
| Hub Sponsorship | £1500 | £1000 | £500 |
| General Brand Advertising | £2250 | £1750 | £1000 |
| Email Digest Sponsorship | £3500 | £2250 | £1000 |
| Promoted Content | £1200 | £600 | £400 |



## Hub Sponsorship

Become a ‘Hub Sponsor’ and serve as the representative company of specific
industry sector(s)

Perfect for businesses that want to target users who are interested in a particular sector (e.g. London office market) Your campaign will appear exclusively at the top of the live feed for the sector(s) of your choice
Your brand will also feature on the daily email digest as the corporate sponsor of your chosen sector(s).

## General Brand Advertising

Infabode will display your advert across the platform for maximum exposure to global real estate members, with a guaranteed number of impressions
Your advert will link users directly back to the URL of your choice
The perfect package for advertisers wishing to focus on promoting their brand or services to our entire audience.

## Email Digest Sponsorship

Take the prime advertising position in the header of our daily digest emails,
taking your business directly to our users’ inboxes The digest sponsorship is one of our most popular packages If you take out our Gold package, you
can send a special mail shot to targeted users in the region(s) of your choice.

## Promoted Content

Promote key company reports or announcements across our global real estate platform With the silver package you can target key user groups that you
want your content to be seen by Through the gold package you can further increase the reach and targeting options of your promoted post.

# SUMMARY
* Infabode provides some of the most highly targeted advertising in the industry
* Competitive pricing and flexible options, with tailored packages to suit any budget
* Further discounts are available should you wish to take out a package of products
* Revenue generated from our partnerships will be reinvested back into the marketing of Infabode
* You will be assigned an account manager who will assist at all stages of your campaign and continually review the reach of your advert on a regular basis
`;
// tslint:enable:max-line-length

export default description;

/** Imports */
import React, {
  Component,
  FunctionComponent,
} from 'react';

import {
  Link,
} from 'react-router-dom';

import Moment from 'react-moment';

import { observer } from 'mobx-react';

import { observable } from 'mobx';

import Masonry from 'react-masonry-component';

import {
  Button
} from 'app/components/button';

import {
  BundleEntity,
  BundleEntityDataApi,
  VendorEntity,
} from 'app/entities';

import {
  createMailtoLink,
} from 'app/libs';


import styles from './bundles-list.module.scss';

const Api: FunctionComponent<BundleEntityDataApi> = (props) => {
  const vendor = VendorEntity.getOneByName(props.name);

  return (
    <div className={styles.api}>
      <div className={styles.apiInfo}>
        <div
          className={styles.apiImage}
          style={{
            // tslint:disable-next-line:max-line-length
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/vendors/${props.image})`
          }}
        />

        {vendor && (
          <Link
            className={styles.apiLink}
            to={`/apis/${vendor.apis[0].url}`}
            target={'_blank'}
          >
            {/*<div className={`${styles.angle} ${styles.topLeft}`} />
            <div className={`${styles.angle} ${styles.bottomRight}`} />*/}

            {/*<div className={`${styles.angle} ${styles.bottomLeft}`} />
            <div className={`${styles.angle} ${styles.topRight}`} />*/}
            {/*<div className={styles.arrow}/>*/}

            <div className={styles.text}>
              view vendor
            </div>
          </Link>
        )}
      </div>

      <div className={styles.apiName}>
        {props.name}
      </div>

      <div className={styles.apiRole}>
        {props.role}
      </div>
    </div>
  );
};

const Angles: FunctionComponent = (props) => {
  return (
    <>
      <div className={`${styles.angle} ${styles.topLeft}`} />
      <div className={`${styles.angle} ${styles.topRight}`} />
      <div className={`${styles.angle} ${styles.bottomLeft}`} />
      <div className={`${styles.angle} ${styles.bottomRight}`} />
    </>
  );
};

const Bundle: FunctionComponent<{data: BundleEntity}> = (props) => {
  const {
    data,
  } = props;

  // tslint:disable:max-line-length
  const params = {
    cc: 'info@builtapi.com',
    subject: `Bundle deploy request for ${data.name}`,
    body: `Hello, ${data.user.name}! I would like to deploy the bundle ${data.name} which I discovered on BuiltAPI.com. Please revert back to me to discuss further steps.`,
  };
  // tslint:enable:max-line-length

  const link = createMailtoLink(data.user.email, params);

  return (
    <div className={styles.bundle}>
      <div className={styles.content}>
        <div className={styles.name}>
          {data.name}
        </div>

        <div className={styles.bundleInfo}>
          {data.user && (
            <div className={styles.author}>
              {data.user.name}
            </div>
          )}

          {data.created && (
            <div className={styles.created}>
              <Moment
                fromNow={true}
              >
                {data.created}
              </Moment>
            </div>
          )}
        </div>

        <div className={styles.description}>
          {data.description}
        </div>

        <div className={styles.title}>
          Applicability
        </div>

        <div className={styles.table}>
          <Angles/>

          <div className={styles.row}>
            <div className={styles.cell}>
              {/*<Angles/>*/}

              Domain
            </div>

            <div className={styles.cell}>
              {/*<Angles/>*/}

              {props.data.domain}
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.cell}>
              {/*<Angles/>*/}

              Vertical
            </div>

            <div className={styles.cell}>
              {/*<Angles/>*/}

              {props.data.vertical}
            </div>
          </div>
        </div>

        <div className={styles.title}>
          Impact
        </div>

        <div className={styles.table}>
          <Angles/>

          <div className={styles.row}>
            <div className={styles.cell}>
              {/*<Angles/>*/}

              KPI
            </div>

            <div className={styles.cell}>
              {/*<Angles/>*/}

              {props.data.kpi}
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.cell}>
              {/*<Angles/>*/}

              ROI
            </div>

            <div className={styles.cell}>
              {/*<Angles/>*/}

              {props.data.roi}
            </div>
          </div>
        </div>

        <div className={styles.apis}>
          {data.apis.map((api, index) => (
            <Api
              key={index}
              name={api.name}
              role={api.role}
              image={api.image}
            />
          ))}
        </div>
      </div>

      <a
        className={styles.deploy}
        href={link}
        target={'_blank'}
      >
        <Button size={'large'} color={'gray'} fluid={true}>
          Deploy Bundle
        </Button>
      </a>
    </div>
  );
};

@observer
class BundlesList extends Component {

  @observable
  bundles: BundleEntity[] = [];

  componentDidMount(): void {
    BundleEntity.getAll().then((bundles) => this.bundles = bundles);
  }

  render() {
    return (
      <>
        <Masonry
          className={styles.bundles}
          options={{
            columnWidth: `.${styles.bundle}`,
            itemSelector: `.${styles.bundle}`,
            percentPosition: true
          }}
        >
          {this.bundles.map((bundle, index) => (
            <Bundle
              key={index}
              data={bundle}
            />
          ))}
        </Masonry>
      </>
    );
  }
}


export {
  BundlesList
};

// tslint:disable:max-line-length
const useCases = `
# PROPERTY
## What kinds of questions can data from the /property resource help answer?
    What properties fall within a radius of a given point (lat/long)?
    Are there properties within a zip code with property taxes of a given range?
    Can I validate the authenticity of the owner listed for a property?
    What data is available about a new property owner?
    What properties in a given area have the most current equity or are close to the end of their loan?
# SALES TREND
## What kinds of questions can data from the /salestrend resource help answer?
    How do recent sales within a zip code compare to my houses valuation?
    Are the home sales increasing in amount and count in my area?
# SCHOOL
## What kinds of questions can data from the /school resource help answer?
    Which schools could my kids attend if I live at a certain property?
    What private schools are within a 10 mile radius of a given property?
    What is the test rating, enrollment rates, and special programs offered at a specific school?
# ASSESSMENT
## What kinds of questions can data from the /assessment resource help answer?
    What are the assessment details of properties within a specific area?
    What is the last known tax amount and assessment for a specific property?
    What assessments were added within a given date range?
# AVM
# Can AVM values be calculated without having property characteristics like, square footage, beds, baths?
    Yes. Although it's important to note how an AVM's confidence score can be affected. Below are a couple different scenarios:
    Property details come from the public county records and coverage varies county to county. Some counties simply don't provide this level of detail and all properties used to calculate the valuation would effectively be in the same ‘boat’. In this situation you would expect confidence scores to be a bit higher.
However, if the missing attributes are isolated to a single property and other comparable properties used in the valuation have this data, this may negatively affect the confidence score. 
# SALE
## What kinds of questions can data from the /sale resource help answer?
    What is the last sale price of all the properties within this specific area?
    What properties have sold over the last three months within this price range in this ZIP code?
    What is the the last sale on this specific property?
# SALES HISTORY
    Use the /saleshistory resource to get information about the sales history of a property. Recorded sales history data can be found based on a property’s ID or address.
ATTOM provides 10 years of sales history data.
`;
// tslint:enable:max-line-length

export default useCases;

/** Imports */
import React, {
  Component,
  ReactNode
} from 'react';

import styles from './main.module.scss';


/* Interfaces */
interface Props {
  children: ReactNode;
}


class Main extends Component<Props> {
  public render() {
    return (
      <main className={styles.main}>
        {this.props.children}
      </main>
    );
  }
}

export {
  Main
};

import description from './description';
import useCases from './use-cases';

export const twentyOneSTDataApi = {
  uuid: '20226592-0ef2-4de0-9da3-a15d62e4c078',
  name: '21st Data Api',
  category: 'Property market',
  url: '21st-data-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: '21st Real Estate',
};

import description from './description';
import useCases from './use-cases';

export const prodaApi = {
  uuid: '072aa579-7c0b-4a18-97cc-3b9480faff5e',
  name: 'Proda Api',
  category: 'Property market',
  url: 'proda-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: false,
  vendorName: 'Proda',
};

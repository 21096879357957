import description from './description';
import useCases from './use-cases';

export const docuniteApi = {
  uuid: '39da5779-fbf1-4fe5-9fa1-745117faa299',
  name: 'Docunite API',
  category: 'Property market',
  url: 'docunite-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: 'Docunite',
};

/** Imports */
import {
  ApiEntity
} from 'app/entities';

import {
  VendorMock,
  vendors,
} from 'app/mocks';


class VendorEntity {
  name: string;
  url: string;
  image: string;
  siteUrl: string;
  docsUrl: string;

  get apis(): ApiEntity[] {
    return ApiEntity.getAll().filter(
      (api) => api.vendorName === this.name
    );
  }

  constructor(data: VendorMock) {
    this.name = data.name;
    this.url = data.url;
    this.image = data.image;
    this.siteUrl = data.siteUrl;
    this.docsUrl = data.docsUrl;
  }

  static getAll = (): VendorEntity[] => {
    return vendors.map((vendor) => {
      return new VendorEntity(vendor);
    });
  }

  static getOneByUrl = (url: string): VendorEntity | undefined => {
    return VendorEntity.getAll().find((vendor) => vendor.url === url);
  }

  static getOneByName = (name: string): VendorEntity | undefined => {
    return VendorEntity.getAll().find((vendor) => vendor.name === name);
  }
}


export {
  VendorEntity,
};

/** Imports */
import React, { Component } from 'react';

import { MarkdownRenderer } from 'redoc';

import styles from './markdown.module.scss';


/** Interfaces */
interface Props {
  source: string;
}


class Markdown extends Component<Props> {
  public render() {
    const markdownRenderer = new MarkdownRenderer();

    const markdown = markdownRenderer.renderMd(this.props.source);

    return (
      <div className={styles.markdown}>
        <div dangerouslySetInnerHTML={{__html: markdown}} />
      </div>
    );
  }
}

export {
  Markdown
};

// tslint:disable:max-line-length
const useCases = `
# The Realcube API consists of three essential workflows:
-     Creating a location hierarchy
-     Locating the facilities
-     Maintaining the facilities
`;
// tslint:enable:max-line-length

export default useCases;

/** Imports */
import React, {
  Component,
  ContextType,
} from 'react';

import {
  NavLink,
} from 'react-router-dom';

import { observable } from 'mobx';
import { observer } from 'mobx-react';

import classNames from 'classnames';

import {
  Button,
  Container,
  SignInModal,
  SignUpModal,
} from 'app/components';

import {
  SessionContext,
} from 'app/contexts';

import { UserDropdown } from './user-dropdown';

import styles from './header.module.scss';


@observer
class Header extends Component {

  static contextType = SessionContext;

  context: ContextType<typeof SessionContext>;

  @observable
  isHiddenButtonMenu: boolean = true;

  @observable
  isOpenModalSignIn: boolean = false;

  @observable
  isOpenModalSignUp: boolean = false;

  @observable
  isOpenDropdownUser: boolean = false;

  handleSwitchModal = () => {
    this.isOpenModalSignIn = !this.isOpenModalSignIn;
    this.isOpenModalSignUp = !this.isOpenModalSignUp;
  }

  render() {
    const className = classNames({
      [styles.nav]: true,
      [styles.show]: !this.isHiddenButtonMenu
    });

    const session = this.context;

    return (
      <header className={styles.header}>
        <SignInModal
          isOpen={this.isOpenModalSignIn}
          onRequestClose={() => this.isOpenModalSignIn = false}
          onClickButtonSwitch={this.handleSwitchModal}
        />

        <SignUpModal
          isOpen={this.isOpenModalSignUp}
          onRequestClose={() => this.isOpenModalSignUp = false}
          onClickButtonSwitch={this.handleSwitchModal}
        />

        <Container>
          {/*<Link to='/' className={styles.logo}/>*/}

          <a
            className={styles.logo}
            href={process.env.REACT_APP_LANDING_URL}
          />

          <nav className={className}>
            <div className={styles.navItemWrap}>
              <NavLink
                to={'/'}
                className={styles.navItem}
              >
                Vendors
              </NavLink>
            </div>

            <div className={styles.navItemWrap}>
              <NavLink
                to={'/bundles'}
                className={styles.navItem}
              >
                Bundles
              </NavLink>
            </div>

            {!session && (
              <div className={styles.navItemWrap}>
                <div className={styles.navItem}>
                  <Button
                    size={'small'}
                    onClick={() => this.isOpenModalSignIn = true}
                  >
                    Sign In
                  </Button>
                </div>
              </div>
            )}

            {!session && (
              <div className={styles.navItemWrap}>
                <div className={styles.navItem}>
                  <Button
                    size={'small'}
                    color={'red'}
                    onClick={() => this.isOpenModalSignUp = true}
                  >
                    Sign Up
                  </Button>
                </div>
              </div>
            )}

            {session && (
              <>
                <div className={styles.navItemWrap}>
                  <div
                    className={`${styles.navItem} ${styles.collapse}`}
                    onClick={
                      () => this.isOpenDropdownUser = !this.isOpenDropdownUser
                    }
                  >
                    {session.name}
                  </div>

                  {this.isOpenDropdownUser && (
                    <UserDropdown
                      handleClickOutside={
                        () => this.isOpenDropdownUser = !this.isOpenDropdownUser
                      }
                    />
                  )}
                </div>
              </>
            )}
          </nav>

          <div
            className={styles.button}
            onClick={() => this.isHiddenButtonMenu = !this.isHiddenButtonMenu}
          />
        </Container>
      </header>
    );
  }
}


export {
  Header,
};

import description from './description';
import useCases from './use-cases';

export const attomDataAreaApi = {
  uuid: '01eebf5b-92b6-414e-a335-e74661a24fbe',
  name: 'Attom Area Api',
  category: 'Property market',
  url: 'attom-data-area-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: 'Attom Data',
};

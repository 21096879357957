/** Imports */
import React, { Component } from 'react';

import {
  Markdown
} from 'app/components';

import styles from './description.module.scss';


/** Types */
type Props = {
  data: string;
};


class Description extends Component<Props> {
  render() {
    return (
      <div className={styles.description}>
        <Markdown source={this.props.data} />
      </div>
    );
  }
}


export {
  Description
};

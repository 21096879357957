/** Imports */


/** Types */
export type BundleEntityData = {
  uuid: string;
  user: {
    name: string;
    email: string;
  }
  name: string;
  description: string;
  kpi: string;
  roi: string;
  domain: string;
  vertical: string;
  apis: BundleEntityDataApi[];
  created: string;
  updated: string;
};

export type BundleEntityDataApi = {
  name: string;
  role: string;
  image: string;
};


class BundleEntity {
  private static url = `${process.env.REACT_APP_BACKEND_URL}/api/v1/bundles`;
  readonly uuid?: string;

  user: {
    name: string;
    email: string;
  };

  name: string;
  description: string;
  kpi: string;
  roi: string;
  domain: string;
  vertical: string;

  apis: BundleEntityDataApi[];

  created: string;
  updated: string;

  constructor(data: BundleEntityData) {
    this.uuid = data.uuid;

    this.user = data.user;

    this.name = data.name;
    this.description = data.description;
    this.kpi = data.kpi;
    this.roi = data.roi;
    this.domain = data.domain;
    this.vertical = data.vertical;

    this.apis = data.apis;

    this.created = data.created;
    this.updated = data.updated;
  }

  static getAll = async (): Promise<BundleEntity[]> => {
    try {
      const response = await fetch(BundleEntity.url, {
        method: 'GET',
        credentials: 'include',
      });

      const res = await response.json();

      return res.data.bundles.map((bundle: BundleEntityData) => {
        return new BundleEntity(bundle);
      });
    } catch (e) {
      console.log(e);

      return [];
    }
  }

  // tslint:disable-next-line:max-line-length
  static create = async (data: Pick<
    BundleEntityData,
    'name' | 'description' | 'kpi' | 'roi' | 'domain' | 'vertical' | 'apis'
    >): Promise<boolean> => {
    const response = await fetch(BundleEntity.url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(data)
    });

    const res = await response.json();

    return res.stauts === 'success';
  }
}


export {
  BundleEntity,
};

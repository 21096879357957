/**  Imports */
import React, { Component, ReactNode } from 'react';

import styles from './container.module.scss';


/**  Interfaces */
interface Props {
  children: ReactNode;
}


class Container extends Component<Props> {
  render() {
    return (
      <section className={styles.section}>
        {this.props.children}
      </section>
    );
  }
}


export {
  Container,
};

import description from './description';
import useCases from './use-cases';

export const homesConnectApi = {
  uuid: 'ff847d60-8fff-4f14-913c-36b0bfb6f2de',
  name: 'Homes Connect Api',
  category: 'Property market',
  url: 'homes-connect-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: 'Homes',
};

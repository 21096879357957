/** Imports */
import React, { Component } from 'react';

import { observable } from 'mobx';
import { observer } from 'mobx-react';

import classNames from 'classnames';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

import styles from './private-field.module.scss';


/** Interfaces */
type Props = {
  value: string;
};


@observer
class PrivateField extends Component<Props> {

  @observable
  isHidden: boolean = true;

  onClickCopy = () => {
    toast.success('Copied');
  }

  onClickShow = () => {
    this.isHidden = !this.isHidden;
  }

  public render() {
    const classNameShow = classNames({
      [styles.show]: true,
      [styles.hidden]: this.isHidden,
    });

    const type = this.isHidden ? 'password' : 'text';

    return (
      <>
        <div className={styles.content}>
          <div className={styles.title}>
            X-BUILTAPI-AUTH-TOKEN
          </div>

          <input
            className={styles.field}
            type={type}
            disabled={true}
            value={this.props.value}
          />

          <div className={classNameShow} onClick={this.onClickShow} />

          <CopyToClipboard text={this.props.value} onCopy={this.onClickCopy}>
            <div className={styles.copy} />
          </CopyToClipboard>
        </div>
      </>
    );
  }
}

export {
  PrivateField
};

import description from './description';
import useCases from './use-cases';

export const algolyApi = {
  uuid: 'a97ddeee-e31e-4ba6-9f21-25f93e7dfb32',
  name: 'Algoly Api',
  category: 'Property market',
  url: 'algoly-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: 'Algoly',
};

/** Imports */
import React, {
  FunctionComponent,
  ReactNode,
} from 'react';

import styles from './tabs.module.scss';


/** Types */
type Props = {
  children: ReactNode;
};


const Tabs: FunctionComponent<Props> = (props) => {
  return (
    <div className={styles.tabs}>
      {props.children}
    </div>
  );
};


export {
  Tabs
};

/** Imports */
import { Spec as Swagger } from 'swagger-schema-official';

// @ts-ignore
import OpenAPISnippet from 'openapi-snippet';

import {
  ApiMock,
  apis,
} from 'app/mocks';

import {
  SessionEntity,
  VendorEntity,
} from 'app/entities';

const targets = {
  shell_curl: 'Shell',
  node_native: 'Node.js',
  go_native: 'Go',
  php_curl: 'PHP',
  // c_libcurl : 'C',
  csharp_restsharp: 'C#',
  javascript_xhr: 'JS',
  java_okhttp: 'Java',
  python_python3: 'Python',
  ruby_native: 'Ruby',
  swift_nsurlsession: 'Swift',
};

const header = (session?: SessionEntity) => {
  const authToken = session ? session.authToken : '<x-builtapi-auth-token>';

  return {
    in: 'header',
    name: 'x-builtapi-auth-token',
    schema: {
      type: 'string',
      default: authToken
    },
    required: true,
  };
};


const prepareOpenAPI = (api: ApiMock, session?: SessionEntity) => {
  const paths = api.swagger.paths;

  for (const path in paths) {
    if (!paths.hasOwnProperty(path)) {
      continue;
    }

    const methods = paths[path];

    for (const method in methods) {
      if (!methods.hasOwnProperty(method)) {
        continue;
      }

      // @ts-ignore
      if (!methods[method].parameters) {
        // @ts-ignore
        api.swagger.paths[path][method].parameters = [];
      }

      // @ts-ignore
      api.swagger.paths[path][method].parameters.push(header(session));

      const result = OpenAPISnippet.getEndpointSnippets(
        api.swagger,
        path,
        method,
        Object.keys(targets),
      );

      // @ts-ignore
      // tslint:disable:max-line-length
      api.swagger.paths[path][method]['x-code-samples'] = result.snippets.map((snippet: any) => {
        return {
          // @ts-ignore
          lang: targets[snippet.id],
          source: snippet.content,
        };
      });
      // tslint:enable:max-line-length
    }
  }

  return api;
};


class ApiEntity {
  readonly uuid: string;

  name: string;
  category: string;
  url: string;
  description: string;
  useCases: string;
  swagger: Swagger;
  locked: boolean;
  vendorName: string;

  get vendor(): VendorEntity {
    const result = VendorEntity.getAll().find(
      (vendor) => vendor.name === this.vendorName
    );

    if (!result) {
      throw new Error(`Missing vendor: ${this.vendorName}`);
    }

    return result;
  }

  constructor(data: ApiMock) {
    this.uuid = data.uuid;

    this.name = data.name;
    this.category = data.category;
    this.url = data.url;
    this.description = data.description;
    this.useCases = data.useCases;
    this.swagger = data.swagger;
    this.locked = data.locked;
    this.vendorName = data.vendorName;
  }

  static getAll = (): ApiEntity[] => {
    return apis.map((api) => new ApiEntity(api));
  }

  static getOneByUrl = (
    url: string,
    session?: SessionEntity
  ): ApiEntity | undefined => {
    const api = apis.find((item) => item.url === url);

    if (!api) {
      return undefined;
    }

    return new ApiEntity(prepareOpenAPI(api, session));
  }
}


export {
  ApiEntity,
};

import description from './description';
import useCases from './use-cases';

export const tridifyApi = {
  uuid: 'a43526c5-f4ee-48c9-b85b-acc396a607d1',
  name: 'Tridify Api',
  category: 'Property market',
  url: 'tridify-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: 'Tridify',
};

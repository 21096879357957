

class SessionEntity {
  name: string;
  email: string;
  authToken: string;
  created: string;
  updated: string;

  constructor(data: SessionEntity) {
    this.name = data.name;
    this.email = data.email;
    this.authToken = data.authToken;
    this.created = data.created;
    this.updated = data.updated;
  }

  static get = () => {
    // todo
  }

  static login = () => {
    // todo
  }

  static logout = () => {
    // todo
  }
}

export {
  SessionEntity,
};

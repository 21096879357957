/** Imports */
import React, {
  ChangeEvent,
  Component,
  FormEvent
} from 'react';

import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { toast } from 'react-toastify';
import * as Yup from 'yup';

import {
  Button,
  Input,
  Modal,
} from 'app/components';

import styles from './sign-in-modal.module.scss';


/** Types */
type Props = {
  onRequestClose: () => void;
  onClickButtonSwitch: () => void;
} & typeof defaultProps;

type Form = {
  email: string;
};

type Errors = Form;

/** Constants */
const defaultProps = {
  isOpen: false,
};

const schema = Yup.object({
  email: Yup.string().trim().email().required(),
});


@observer
class SignInModal extends Component<Props> {

  @observable
  isOpen: boolean = false;

  @observable
  form: Form = {email: ''};

  @observable
  errors: Errors = {email: ''};

  _url = `${process.env.REACT_APP_BACKEND_URL}/api/v1/session/login`;

  get isValidForm() {
    const {
      email,
    } = this.errors;

    return email === '';
  }

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      name,
      value,
    } = event.target;

    this.form = {
      ...this.form,
      [name]: value.trim()
    };

    this.errors = {
      ...this.errors,
      [name]: ''
    };
  };

  onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    schema.validate(this.form, {
      abortEarly: false,
      stripUnknown: true,
    }).then((form) => {
      fetch(this._url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(form),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === 'success' && res.data.ok) {
            this.props.onRequestClose();
            toast.success('Please check your email.');
          }
        });
    }).catch((err: Yup.ValidationError) => {
      err.inner.forEach((value) => {
        this.errors = {
          ...this.errors,
          [value.path]: value.message,
        };
      });
    });
  };

  public render() {

    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
      >
        <div className={styles.title}>
          SIGN IN
        </div>

        <div className={styles.note}>
          Type your email you used to sign up, and we'll send you a link that
          will sign you in to BuiltAPI instantly.
        </div>

        <form
          className={styles.form}
          onSubmit={this.onSubmit}
          autoComplete={'off'}
        >
          <Input
            title={'Email'}
            name={'email'}
            error={this.errors.email}
            handleChange={this.handleChange}
          />

          <Button color={'red'} fluid={true} disabled={!this.isValidForm}>
            SUBMIT
          </Button>
        </form>

        <div className={styles.switch}>
          <span className={styles.text}>
            Dont have account?
          </span>

          <span
            className={styles.button}
            onClick={this.props.onClickButtonSwitch}
          >
            SIGN UP
          </span>
        </div>
      </Modal>
    );
  }
}


export {
  SignInModal
};

import description from './description';
import useCases from './use-cases';

export const twentyOneSTAuthorizationApi = {
  uuid: 'b70d7f62-4fea-4686-96b1-b1259592e58a',
  name: '21st Authorization Api',
  category: 'Property market',
  url: '21st-authorization-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: '21st Real Estate',
};

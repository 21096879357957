// tslint:disable:max-line-length
const useCases = `
VIEW ANALYSIS - A high-performance rasterisation-based method for approximating viewing conditions and visual connectivity.\n
GEOMETRICAL ANALYSIS - A comprehensive set of evaluations for fast semantic geometry assessment.\n
CONNECTIVITY ANALYSIS - A scalable analysis of logistic connectivity capable of high-resolution interior and detailed urban distance calculation.\n
PHYSICAL ANALYSIS - A holistic set of approximations for transmission calculations used in thermal, acoustic, static, and radiation simulation.
`;
// tslint:enable:max-line-length

export default useCases;

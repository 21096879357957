import description from './description';
import useCases from './use-cases';

export const breeamApi = {
  uuid: 'b0d8a952-8614-4eaa-8118-1fcac2d1079e',
  name: 'Breeam Api',
  category: 'Property market',
  url: 'breeam-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: 'Breeam',
};

/** Imports */
import React, {
  ChangeEvent,
  Component,
} from 'react';

import ClassNames from 'classnames';

import styles from './input.module.scss';


/** Types */
type Props = {
  name: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
} & typeof defaultProps;

type Type = 'text' | 'email' | 'password';


/** Constants */
const defaultProps = Object.freeze({
  title: '',
  value: '',
  placeholder: '',
  type: 'text' as Type,
  error: '',
});


class Input extends Component<Props> {
  static readonly defaultProps = defaultProps;

  render() {
    const className = ClassNames({
      [styles.label]: true,
      [styles.hasError]: this.props.error !== ''
    });

    return (
      <label className={className}>
        <span className={styles.title}>
          {this.props.title}
        </span>

        <span className={styles.error}>
          {this.props.error}
        </span>

        <input
          className={styles.input}
          type={this.props.type}
          placeholder={this.props.placeholder}
          name={this.props.name}
          onChange={this.props.handleChange}
        />
      </label>
    );
  }
}

export {
  Input
};

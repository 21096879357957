/** Imports */
import React, {
  Component,
  MouseEventHandler,
  ReactNode
} from 'react';

import ClassNames from 'classnames';

import styles from './button.module.scss';


/** Types */
type Props = {
  size: Size;
  color: Color;
  fluid: boolean;
  disabled: boolean;
  active: boolean;
  onClick: MouseEventHandler;
  children: ReactNode;
};

type Size = 'small' | 'regular' | 'large';
type Color = 'gray' | 'orange' | 'red';


class Button extends Component<Props> {
  static defaultProps = {
    size: 'regular',
    color: 'gray',
    fluid: false,
    disabled: false,
    active: false,
    onClick: () => undefined,
  };

  render() {
    const classNames = ClassNames({
      [styles.button]: true,

      [styles.small]: this.props.size === 'small',
      [styles.regular]: this.props.size === 'regular',
      [styles.large]: this.props.size === 'large',

      [styles.gray]: this.props.color === 'gray',
      [styles.orange]: this.props.color === 'orange',
      [styles.red]: this.props.color === 'red',

      [styles.fluid]: this.props.fluid,

      [styles.active]: this.props.active,
    });

    return (
      <button
        className={classNames}
        disabled={this.props.disabled}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </button>
    );
  }
}

export {
  Button
};

/** Imports */
import React, { Component } from 'react';

import onClickOutside from 'react-onclickoutside';

import styles from './user-dropdown.module.scss';


/** Types */
type Props = {
  handleClickOutside: () => void
};


class UserDropdown extends Component<Props> {
  _url = `${process.env.REACT_APP_BACKEND_URL}/api/v1/session/logout`;

  handleLogout = () => {
    fetch(this._url, {
      method: 'GET',
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'success') {
          window.location.href = '/';
        }
      });
  }

  handleClickOutside = () => {
    this.props.handleClickOutside();
  }


  render() {
    return (
      <div className={styles.content}>
        <div
          className={styles.item}
          onClick={this.handleLogout}
        >
          logout
        </div>
      </div>
    );
  }
}

const userDropdown = onClickOutside(UserDropdown);

export {
  userDropdown as UserDropdown
};

/** Imports */
import React, {
  Component,
  ReactNode
} from 'react';

import styles from './away-link.module.scss';


/** Types */
type Props = {
  url: string;
  children: ReactNode;
};


class AwayLink extends Component<Props> {
  render() {
    return (
      <a
        href={this.props.url}
        className={styles.awayLink}
        rel='noopener noreferrer'
        target='_blank'
      >
        <span className={styles.text}>
          {this.props.children}
        </span>

        <div className={styles.icon}/>
      </a>
    );
  }
}

export {
  AwayLink
};

import description from './description';
import useCases from './use-cases';

export const gresbApi = {
  uuid: '77d4e1cf-2e0a-40ef-9d2c-972f635a925e',
  name: 'GRESB Api',
  category: 'Property market',
  url: 'gresb-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: 'GRESB',
};

import description from './description';
import useCases from './use-cases';

export const twentyOneSTFirstIndicationResidentialObjectApi = {
  uuid: '6452c704-5c7b-4a07-954b-3bcf9c1521c7',
  name: '21st First Indication: Residential Object Api',
  category: 'Property market',
  url: '21st-first-indication-residential-object-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: '21st Real Estate',
};

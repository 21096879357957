/** Imports */
import React, {
  Component,
} from 'react';

import {
  Redirect,
  Route, RouteComponentProps, Switch
} from 'react-router';

import {
  Container,
  Hero,
  Tab,
  Tabs,
} from 'app/components';

import { BundlesCreate } from './bundles-create';
import { BundlesList } from './bundles-list';


/** Types */
type Props = RouteComponentProps;


/** Constants */
// tslint:disable:max-line-length
const hero = {
  titleLight: 'Connecting the Curious',
  titleBold: 'Masterminds of Proptech',
  description: 'Create bundles of proptech solutions to drive ROI-first built technology development.',
};
// tslint:enable:max-line-length


class BundlesPage extends Component<Props> {
  render() {
    const url = this.props.match.url;

    return (
      <>
        <Hero
          titleLight={hero.titleLight}
          titleBold={hero.titleBold}
          description={hero.description}
        />

        <Container>
          <Tabs>
            <Tab to={`${url}/list`}>Bundles</Tab>

            <Tab to={`${url}/create`}>New bundle</Tab>
          </Tabs>

          <Switch>
            <Redirect from={url} to={`${url}/list`} exact={true}/>

            <Route
              path={`${url}/list`}
              component={BundlesList}
            />

            <Route
              path={`${url}/create`}
              component={BundlesCreate}
            />
          </Switch>
        </Container>
      </>
    );
  }
}


export {
  BundlesPage
};

/** Imports */
import React, { Component } from 'react';

import styles from './troubleshooting.module.scss';


/** Types */
type Props = {
  data: any;
};


class Troubleshooting extends Component<Props> {
  render() {
    return (
      <div className={styles.tr}>
        {this.props.data}
      </div>
    );
  }
}


export {
  Troubleshooting
};

/** Imports */
import React, {
  Component,
} from 'react';

import {
  observable
} from 'mobx';

import {
  observer
} from 'mobx-react';

import {
  Button,
  Container,
  Hero,
  Item,
} from 'app/components';

import {
  ApiEntity,
  VendorEntity,
} from 'app/entities';

import styles from './index-page.module.scss';


/** Types */
type ListType = 'apis' | 'vendors';


/** Constants */
  // tslint:disable:max-line-length
const hero = {
    titleLight: 'Connecting the Curious',
    titleBold: 'Masterminds of Proptech',
    description: 'Discover and connect with API-driven built world tech developers globally.',
  };
// tslint:enable:max-line-length


@observer
class IndexPage extends Component {
  @observable
  listType: ListType = 'vendors';

  get list(): any {
    if (this.listType === 'vendors') {
      const vendors = VendorEntity.getAll();

      return vendors.map((vendor) => <Item data={vendor} key={vendor.name}/>);
    }

    if (this.listType === 'apis') {
      const apis = ApiEntity.getAll();

      return apis.map((api) => <Item data={api} key={api.uuid}/>);
    }

    return [];
  }

  render() {
    return (
      <>
        <Hero
          titleLight={hero.titleLight}
          titleBold={hero.titleBold}
          description={hero.description}
        />

        <Container>
          <div className={styles.controls}>
            <div className={styles.control}>
              <Button
                size={'regular'}
                active={this.listType === 'apis'}
                onClick={() => this.listType = 'apis'}
              >
                APIS
              </Button>

              <Button
                size={'regular'}
                active={this.listType === 'vendors'}
                onClick={() => this.listType = 'vendors'}
              >
                VENDORS
              </Button>
            </div>
          </div>

          <div className={styles.list}>
            {this.list}
          </div>
        </Container>
      </>
    );
  }
}


export {
  IndexPage
};

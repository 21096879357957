import description from './description';
import useCases from './use-cases';

export const attomDataPropertyApi = {
  uuid: 'a3068ffc-7736-4219-84cb-a2da51a22197',
  name: 'Attom Property Api',
  category: 'Property market',
  url: 'attom-data-property-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: 'Attom Data',
};

/** Imports */
import React, {
  Component,
  ContextType,
} from 'react';

import {
  NavLink,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
} from 'react-router-dom';

import {
  observable
} from 'mobx';

import {
  observer
} from 'mobx-react';

import classNames from 'classnames';

import ScrollMenu from 'react-horizontal-scrolling-menu';

import { ApiEntity } from 'app/entities';

import {
  AwayLink,
  Container,
} from 'app/components';
import {SessionContext} from '../../contexts';

import { Description } from './description';
import { Endpoints } from './endpoints';
import { Troubleshooting } from './troubleshooting';
import { UseCases } from './use-cases';

import styles from './api-page.module.scss';


/** Types */
type Props = RouteComponentProps<Params>;

type Params = {
  apiUrl: string;
};

type LockHelper = {
  classNames: string;
  title: string;
};


/** Helpers */


@observer
class ApiPage extends Component<Props> {
  static contextType = SessionContext;

  context: ContextType<typeof SessionContext>;

  @observable
  api?: ApiEntity;

  get apiTabs() {
    if (!this.api) {
      return [];
    }

    const apis = this.api.vendor.apis;

    return apis.map((api, i) => (
      <NavLink
        to={`/apis/${api.url}`}
        className={styles.apiTab}
        activeClassName={styles.active}
        key={i}
      >
        {api.name}

        <div
          className={this.lockHelper(api).classNames}
          title={this.lockHelper(api).title}
        />
      </NavLink>
    ));
  }

  componentDidMount(): void {
    this.api = ApiEntity.getOneByUrl(
      this.props.match.params.apiUrl, this.context
    );
  }

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.match.params.apiUrl === this.props.match.params.apiUrl) {
      return;
    }

    this.api = ApiEntity.getOneByUrl(
      this.props.match.params.apiUrl, this.context
    );
  }

  lockHelper(api: ApiEntity): LockHelper {
    const classNamesLock = classNames({
      [styles.lock]: true,
      [styles.locked]: api.locked,
    });

    const title = api.locked ? 'Additional authentication required.' : '';

    return {
      classNames: classNamesLock,
      title: title,
    };
  }

  render() {
    const url = this.props.match.url;
    if (!this.api) {
      return (
        <div>API not found</div>
      );
    }

    const api = this.api;
    const vendor = this.api.vendor;

    const style = {
      backgroundImage:
        `url(${process.env.PUBLIC_URL}/images/vendors/${vendor.image})`,
    };

    return (
      <>
        <Container>
          <div className={styles.vendor}>
            <div className={styles.icon} style={style} />

            <div className={styles.name}>
              <AwayLink url={vendor.siteUrl}>
                {vendor.name}
              </AwayLink>
            </div>
          </div>

          <div className={styles.content}>
            <div className={styles.apiTabs}>
              <ScrollMenu
                data={this.apiTabs}
                alignCenter={false}
                wheel={false}
              />
            </div>

            <div className={styles.tabs}>
              <NavLink
                to={`${url}/endpoints`}
                className={styles.tab}
                activeClassName={styles.active}
              >
                Endpoints
              </NavLink>

              <NavLink
                to={`${url}/description`}
                className={styles.tab}
                activeClassName={styles.active}
              >
                Description
              </NavLink>

              <NavLink
                to={`${url}/use-cases`}
                className={styles.tab}
                activeClassName={styles.active}
              >
                Use Cases
              </NavLink>

              <NavLink
                to={`${url}/troubleshooting`}
                className={styles.tab}
                activeClassName={styles.active}
              >
                Troubleshooting
              </NavLink>
            </div>

            <Switch>
              <Redirect from={url} to={`${url}/endpoints`} exact={true}/>

              <Route
                path={`${url}/endpoints`}
                render={() => (<Endpoints swagger={api.swagger}/>)}
              />

              <Route
                path={`${url}/description`}
                render={() => (<Description data={api.description}/>)}
              />

              <Route
                path={`${url}/use-cases`}
                render={() => (<UseCases data={api.useCases}/>)}
              />

              <Route
                path={`${url}/troubleshooting`}
                render={() => (<Troubleshooting data={''}/>)}
              />
            </Switch>
          </div>
        </Container>
      </>
    );
  }
}


export {
  ApiPage
};

/** Imports */

import React, {
  Component,
  ContextType,
} from 'react';

import {
  observable,
} from 'mobx';

import {
  observer,
} from 'mobx-react';

import {
  SessionContext,
} from 'app/contexts';

import {
  VendorEntity,
} from 'app/entities';

import {
  BundleForm,
} from 'app/forms';

import styles from './bundles-create.module.scss';


@observer
class BundlesCreate extends Component {
  static contextType = SessionContext;

  context: ContextType<typeof SessionContext>;

  @observable
  vendors: VendorEntity[] = [];

  componentDidMount(): void {
    this.vendors = VendorEntity.getAll();
  }

  render() {
    if (!this.context) {
      return (
        <div className={styles.note}>
          Please sign in to begin creating new solution bundles.
        </div>
      );
    }

    return (
      <BundleForm/>
    );
  }
}


export {
  BundlesCreate
};

// tslint:disable:max-line-length
const useCases = `
1. Let our AI NEO do the work for you by recognizing and classifying your documents instantly after they have been uploaded to docunite(r)

2. Collaborate with internal team members or external partners by always having access to the single source of truth of your documents

3. Benefit from more transparency to be always prepared for ESG, BaFin and upcoming regulations

4. Be always transaction-ready to sell assets fast and with high quality, because all actual documents are in place and by using sophisticated and smart Q&A processes with your external partners and investors.
`;
// tslint:enable:max-line-length

export default useCases;

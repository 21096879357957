/** Imports */
import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import className from 'classnames';

import {
  Button
} from 'app/components';

import {
  ApiEntity,
  VendorEntity
} from 'app/entities';

import styles from './item.module.scss';


/** Types */
type Props = {
  data: ApiEntity | VendorEntity;
};


class Item extends Component<Props> {
  render() {
    const {
      data,
    } = this.props;

    let image;
    let locked;
    let name;
    let category;
    let url;
    let button;

    if (data instanceof ApiEntity) {
      image = data.vendor.image;
      locked = data.locked;
      name = data.name;
      category = data.category;
      url = `/apis/${data.url}`;
      button = 'OPEN API';
    } else {
      image = data.image;
      name = data.name;
      url = `/apis/${data.apis[0].url}`;
      button = 'OPEN API LIST';
    }

    const imageUrl = encodeURI(
      `${process.env.PUBLIC_URL}/images/vendors/${image}`
    );

    const style = {
      backgroundImage: `url(${imageUrl})`,
    };

    const classNameLock = className({
      [styles.lock]: true,
      [styles.locked]: locked,
    });

    const title = locked ? 'Additional authentication required.' : '';

    return (
      <div className={styles.apiItem}>
        <Link
          to={url}
          className={styles.image}
          style={style}
        />

        {locked !== undefined && (
          <div
            className={classNameLock}
            title={title}
          />
        )}

        <div className={styles.info}>
          <div className={`${styles.angle} ${styles.topLeft}`} />
          <div className={`${styles.angle} ${styles.topRight}`} />
          <div className={`${styles.angle} ${styles.bottomLeft}`} />
          <div className={`${styles.angle} ${styles.bottomRight}`} />

          <div className={styles.name}>
            {name}
          </div>

          {category && (
            <div className={styles.category}>
              {category}
            </div>
          )}
        </div>

        <Link to={url}>
          <Button size={'large'} color={'gray'} fluid={true}>
            {button}
          </Button>
        </Link>
      </div>
    );
  }
}

export {
  Item
};

/** Imports */
import React, {
  Component,
  ReactNode,
} from 'react';

import ReactModal from 'react-modal';

import styles from './modal.module.scss';


/** Types */
type Props = {
  children: ReactNode;
  onRequestClose: () => void;
} & typeof defaultProps;


/** Constants */
const defaultProps = {
  isOpen: false,
};


class Modal extends Component<Props> {
  public render() {
    return (
      <ReactModal
        isOpen={this.props.isOpen}
        overlayClassName={styles.overlay}
        className={styles.content}
        shouldCloseOnOverlayClick={true}
        onRequestClose={this.props.onRequestClose}
        ariaHideApp={false}
      >
        {this.props.children}
      </ReactModal>
    );
  }
}


export {
  Modal
};

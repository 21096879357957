import description from './description';
import useCases from './use-cases';

export const assettiDemoApi = {
  uuid: 'f5c8644f-3709-4393-aa07-dc76e347edf9',
  name: 'Assetti Demo Api',
  category: 'Property market',
  url: 'assetti-demo-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: false,
  vendorName: 'Assetti',
};

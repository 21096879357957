/** Imports */
import {
  Component,
  ReactNode,
} from 'react';

import {
  RouteComponentProps,
  withRouter
} from 'react-router-dom';


/** Types */
type Props = {
  children?: ReactNode,
} & RouteComponentProps;


class ScrollToTop extends Component<Props> {
  componentDidUpdate(prevProps: Props): void {
    if (this.props.location.pathname === prevProps.location.pathname) {
      return;
    }

    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

const scrollToTop = withRouter(ScrollToTop);


export {
  scrollToTop as ScrollToTop,
};

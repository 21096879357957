/** Imports */
import React, { Component } from 'react';

import {
  Markdown
} from 'app/components';

import styles from './use-cases.module.scss';


/** Interfaces */
interface Props {
  data: any;
}


class UseCases extends Component<Props> {
  public render() {
    return (
      <div className={styles.useCases}>
        <Markdown source={this.props.data} />
      </div>
    );
  }
}


export {
  UseCases
};

import description from './description';
import useCases from './use-cases';

export const infabodeApi = {
  uuid: 'd3320268-0e95-40c1-b51a-5c4d711a0c21',
  name: 'Infabode',
  category: 'Property market',
  url: 'infabode-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: 'Infabode',
};

// tslint:disable:max-line-length
const useCases = `
These are 14 business categories that exist in the Point of Interest API. They are:

2.     ATTRACTIONS – RECREATION
3.     AUTOMOTIVE SERVICES
4.     BANKS – FINANCIAL
5.     EATING – DRINKING
6.     EDUCATION
7.     FARM – RANCH
8.     GOVERNMENT – PUBLIC
9.     HEALTH CARE SERVICES
10.     HOSPITALITY
11.     ORGANIZATIONS – ASSOCIATIONS
12.     PERSONAL SERVICES
13.     PET SERVICES
14.     SHOPPING
15.     TRAVEL
`;
// tslint:enable:max-line-length

export default useCases;

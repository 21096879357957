import description from './description';
import useCases from './use-cases';

export const archilyseApi = {
  uuid: 'f2e9d879-3a48-40c2-b6cd-e11bbe0e623a',
  name: 'Archilyse Api',
  category: 'Property market',
  url: 'archilyse-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: 'Archilyse',
};

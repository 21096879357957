import description from './description';
import useCases from './use-cases';

export const sensorbergMobileSdkApi = {
  uuid: '63d6559c-dade-4a66-99b3-35d3469e16e7',
  name: 'Sensorberg MobileSDK API',
  category: 'Property market',
  url: 'sensorberg-mobile-sdk-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: 'Sensorberg',
};

import description from './description';
import useCases from './use-cases';

export const twentyOneSTRentIndexApi = {
  uuid: 'd8cef1b9-71ef-4ecb-8b1c-1a428b4ff6f5',
  name: '21st Rent Index Api',
  category: 'Property market',
  url: '21st-rent-index-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: '21st Real Estate',
};

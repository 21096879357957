/** Imports */
import React, {
  FunctionComponent,
} from 'react';

import {
  BundleEntityDataApi,
  VendorEntity,
} from 'app/entities';

import styles from './vendor.module.scss';


/** Types */
type VendorProps = Pick<VendorEntity, 'name' | 'url' | 'image'> & {
  handleClick: (api: BundleEntityDataApi) => void;
};


const Vendor: FunctionComponent<VendorProps> = (props) => {
  return (
    <div className={styles.vendor}>
      <div className={styles.content}>
        <div className={styles.name}>
          {props.name}
        </div>

        <div
          className={styles.image}
          style={{
            // tslint:disable-next-line:max-line-length
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/vendors/${props.image})`
          }}
        />

        <div
          className={styles.button}
          onClick={() => props.handleClick({
            name: props.name,
            image: props.image,
            role: '',
          })}
        >
          <div className={styles.arrow}/>

          <div className={styles.text}>
            Add to bundle
          </div>
        </div>
      </div>
    </div>
  );
};

export {
  Vendor
};

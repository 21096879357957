import description from './description';
import useCases from './use-cases';

export const casaviManagementApi = {
  uuid: 'c6bc6c8d-6fec-43c9-b510-f1b83284970c',
  name: 'Casavi Management Api',
  category: 'Property market',
  url: 'casavi-management-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: 'Casavi',
};

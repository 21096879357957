/** Imports */
import React, {
  FunctionComponent,
  useState,
} from 'react';

import {
  BundleEntityDataApi,
  VendorEntity,
} from 'app/entities';

import { Vendor } from '../vendor';

import styles from './vendors.module.scss';

type Props = {
  handleClick: (api: BundleEntityDataApi) => void;
};


const Vendors: FunctionComponent<Props> = (props) => {
  const [vendors] = useState(VendorEntity.getAll);

  return (
    <div className={styles.vendors}>
      {vendors.map((vendor, i) => (
        <Vendor
          key={i}
          name={vendor.name}
          url={vendor.url}
          image={vendor.image}
          handleClick={props.handleClick}
        />
      ))}
    </div>
  );
};

export {
  Vendors
};

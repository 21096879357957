import description from './description';
import useCases from './use-cases';

export const casaviCommunityApi = {
  uuid: 'f69689ac-5716-418e-be98-28141fb6cc14',
  name: 'Casavi Community Api',
  category: 'Property market',
  url: 'casavi-community-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: 'Casavi',
};

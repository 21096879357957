import description from './description';
import useCases from './use-cases';

export const platformOfTrustIdentityApi = {
  uuid: 'b4c8a3a5-da90-4fdd-b7e9-795ef6ce5f1c',
  name: 'POT Identity Api',
  category: 'Property market',
  url: 'platform-of-trust-identity-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: 'Platform Of Trust',
};

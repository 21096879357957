import description from './description';
import useCases from './use-cases';

export const twentyOneSTSmartDataReportApi = {
  uuid: 'bad8a853-f1d9-4bb1-8c94-9764865293cb',
  name: '21st Smart Data Report Api',
  category: 'Property market',
  url: '21st-smart-data-report-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: '21st Real Estate',
};

import description from './description';
import useCases from './use-cases';

export const platformOfTrustMessageApi = {
  uuid: '3d3f092d-775c-4e17-b115-2b49208952e4',
  name: 'POT Message Api',
  category: 'Property market',
  url: 'platform-of-trust-message-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: 'Platform Of Trust',
};

import description from './description';
import useCases from './use-cases';

export const platformOfTrustProductApi = {
  uuid: '200ec94c-9bd0-4323-96db-f03f4cea5dcd',
  name: 'POT Product Api',
  category: 'Property market',
  url: 'platform-of-trust-product-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: 'Platform Of Trust',
};

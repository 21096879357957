// tslint:disable:max-line-length
const useCases = `
# Map GeoDemo

Try out Infabode API using our GeoDemo at https://developer.infabode.com/map/

[![N|Solid](https://i.imgur.com/GWEfz2n.png)](https://developer.infabode.com/map/)


To see Infabode API in action:

* Click on the map (this will select a \`Region\`)
* Select relevant \`Sector\`
* Select published \`Date\`
* Select applicable radius around the point you clicked

The resulting list of \`Posts\` will appear below the map with relevant response details and a correspondent URL to the Infabode frontend.
`;
// tslint:enable:max-line-length

export default useCases;

import description from './description';
import useCases from './use-cases';

export const platformOfTrustContextApi = {
  uuid: 'aabead20-c67a-4751-9b90-e9b7ff9a0b11',
  name: 'POT Content Api',
  category: 'Property market',
  url: 'platform-of-trust-context-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: 'Platform Of Trust',
};

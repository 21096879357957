/** Imports */
import { Spec as Swagger } from 'swagger-schema-official';

import {
  algolyApi,
  archilyseApi,
  assettiDemoApi,
  attomDataAreaApi,
  attomDataCommunityApi,
  attomDataPoiApi,
  attomDataPropertyApi,
  breeamApi,
  builtApiDapDemoProjectApi,
  casaviCommunityApi,
  casaviManagementApi,
  docuniteApi,
  evanaApi,
  gresbApi,
  homesConnectApi,
  infabodeApi,
  platformOfTrustBrokerApi,
  platformOfTrustCalendarApi,
  platformOfTrustContextApi,
  platformOfTrustIdentityApi,
  platformOfTrustMessageApi,
  platformOfTrustProductApi,
  prodaApi,
  realcubeApi,
  redfiveApi,
  sensorbergBackendApi,
  sensorbergMobileSdkApi,
  tridifyApi,
  twentyOneSTAuthorizationApi,
  twentyOneSTDataApi,
  twentyOneSTFirstIndicationResidentialObjectApi,
  twentyOneSTOfferServiceApi,
  twentyOneSTRentIndexApi,
  twentyOneSTSmartDataProductsApi,
  twentyOneSTSmartDataReportApi,
} from './apis';

import {
  algoly,
  archilyse,
  assetti,
  attomData,
  breaam,
  builtApi,
  casavi,
  docunite,
  evana,
  gresb,
  homes,
  infabode,
  platformOfTrust,
  proda,
  realcube,
  redfive,
  sensorberg,
  tridify,
  twentyOneST,
} from './vendors';

import {
  propteches,
} from './propteches';


/** Interfaces */
export interface ApiMock {
  uuid: string;
  name: string;
  category: string;
  url: string;
  description: string;
  useCases: string;
  swagger: Swagger;
  locked: boolean;
  vendorName: string;
}

export interface VendorMock {
  name: string;
  url: string;
  image: string;
  siteUrl: string;
  docsUrl: string;
}

const vendors: VendorMock[] = [
  redfive,
  docunite,
  assetti,
  gresb,
  evana,
  algoly,
  tridify,
  twentyOneST,
  archilyse,
  attomData,
  breaam,
  casavi,
  homes,
  infabode,
  platformOfTrust,
  proda,
  realcube,
  sensorberg,
  builtApi,
];


const targets = {
  shell_curl: 'Shell',
  node_native: 'Node.js',
  go_native: 'Go',
  php_curl: 'PHP',
  // c_libcurl : 'C',
  csharp_restsharp: 'C#',
  javascript_xhr: 'JS',
  java_okhttp: 'Java',
  python_python3: 'Python',
  ruby_native: 'Ruby',
  swift_nsurlsession: 'Swift',
};

const header = {
  in: 'header',
  name: 'x-builtapi-auth-token',
  schema: {
    type: 'string',
    default: '<x-builtapi-auth-token>'
  },
  required: true,
};

const apis: ApiMock[] = [
  redfiveApi,
  docuniteApi,

  assettiDemoApi,

  gresbApi,

  evanaApi,

  algolyApi,

  tridifyApi,

  twentyOneSTAuthorizationApi,
  twentyOneSTDataApi,
  twentyOneSTFirstIndicationResidentialObjectApi,
  twentyOneSTOfferServiceApi,
  twentyOneSTRentIndexApi,
  twentyOneSTSmartDataProductsApi,
  twentyOneSTSmartDataReportApi,

  infabodeApi,

  archilyseApi,

  platformOfTrustBrokerApi,
  platformOfTrustCalendarApi,
  platformOfTrustContextApi,
  platformOfTrustIdentityApi,
  platformOfTrustMessageApi,
  platformOfTrustProductApi,

  prodaApi,

  attomDataAreaApi,
  attomDataCommunityApi,
  attomDataPoiApi,
  attomDataPropertyApi,

  sensorbergBackendApi,
  sensorbergMobileSdkApi,

  realcubeApi,

  breeamApi,

  homesConnectApi,

  casaviCommunityApi,
  casaviManagementApi,

  builtApiDapDemoProjectApi,
];


/** Exports */
export {
  apis,
  propteches,
  vendors,
};

import description from './description';
import useCases from './use-cases';

export const twentyOneSTOfferServiceApi = {
  uuid: '4abd434e-9479-4b78-aaf3-c39811411280',
  name: '21st Offer service Api',
  category: 'Property market',
  url: '21st-offer-service-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: '21st Real Estate',
};

import description from './description';
import useCases from './use-cases';

export const builtApiDapDemoProjectApi = {
  uuid: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
  name: 'BuiltAPI DAP Demo Project Api',
  category: 'Property market',
  url: 'built-api-dap-demo-projet-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: 'BuiltAPI',
};

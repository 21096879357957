import description from './description';
import useCases from './use-cases';

export const realcubeApi = {
  uuid: 'ef44dc69-2243-4535-a697-e218c0e30e82',
  name: 'RealCube Api',
  category: 'Property market',
  url: 'realcube-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: 'RealCube',
};

import description from './description';
import useCases from './use-cases';

export const evanaApi = {
  uuid: '96f00075-390e-439b-85e6-e7ef3c1d9164',
  name: 'Evana Api',
  category: 'Property market',
  url: 'evana-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: 'Evana',
};

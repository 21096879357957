import description from './description';
import useCases from './use-cases';

export const attomDataPoiApi = {
  uuid: '6a632342-6c63-4312-b456-63bdad4d6b44',
  name: 'Attom Poi Api',
  category: 'Property market',
  url: 'attom-data-poi-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: 'Attom Data',
};

/** Imports */
import React, {
  ChangeEvent,
  Component,
  FunctionComponent,
  KeyboardEvent,
  MouseEvent,
  useState,
} from 'react';

import {
  Field,
  FieldArray,
  FieldArrayRenderProps,
  Form,
  Formik,
  FormikActions,
  FormikErrors,
  FormikProps,
  getIn,
} from 'formik';

import * as Yup from 'yup';

import onClickOutside from 'react-onclickoutside';

import { toast } from 'react-toastify';

import {
  Button,
} from 'app/components';

import {
  BundleEntity,
  BundleEntityData,
  BundleEntityDataApi,
  VendorEntity,
} from 'app/entities';

import {
  propteches,
} from 'app/mocks';

import {
  Vendors
} from './components';

import styles from './bundle-form.module.scss';


/** Types */
type SearchProps = {
  arrayHelpers: FieldArrayRenderProps;
};

type BundleFormValues = Pick<
  BundleEntityData,
  'name' | 'description' | 'kpi' | 'roi' | 'domain' | 'vertical' | 'apis'
>;


/** Constants */
const vendors = VendorEntity.getAll();

const kpi = [
  'Estimated Rental Value',
  'Effective Gross Income',
  'Operating Expenses',
  'Net Operating Income',
  'Capex',
  'Interest Expense',
  'Depreciation',
];

const roi = [
  'Market Value',
  'Property IRR',
  'Equity IRR',
  'Investment Return',
  'Capital Return',
  'Total Return',
];

const domains = [
  'Investment and finance',
  'Operations management',
  'Construction and design',
];

const vectors = {
  'Investment and finance': [
    'Location analysis',
    'Concept planning',
    'Investment analysis',
    'Valuation',
    'Cash flow management',
    'Banking',
  ],
  'Operations management': [
    'Market analysis',
    'Building analysis',
    'Customer analytics',
    'Portfolio management',
    'Facility management',
    'Property management',
    'Asset management',
    'CRM',
    'Agent services',
    'Marketplace',
    'Transaction management',
  ],
  'Construction and design': [
    'Master planning',
    'Project design',
    'Construction management',
    'Construction control',
    'Premises fitout and design',
  ],
};


const initialValues = {
  name: '',
  description: '',
  kpi: '',
  roi: '',
  domain: '',
  vertical: '',
  apis: []
};

const apiSchema = Yup.object().shape({
  role: Yup
    .string()
    .trim()
    .required('required'),
  name: Yup
    .string()
    .trim()
    .required('required'),
  image: Yup
    .string()
    .trim()
    .required('required'),
});

const validationSchema = Yup.object().shape({
  name: Yup
    .string()
    .trim()
    .max(255)
    .required('required'),
  description: Yup
    .string()
    .trim()
    .required('required'),
  kpi: Yup
    .string()
    .trim()
    .required('required'),
  roi: Yup
    .string()
    .trim()
    .required('required'),
  domain: Yup
    .string().trim().required('required'),
  vertical: Yup
    .string()
    .trim()
    .required('required'),
  apis: Yup
    .array(apiSchema)
    .min(1, 'minimum of 1 vendors')
    .required('must have vendors'),
});


/** Helpers */
const Search = (props: SearchProps) => {
  const [list, setList] = useState<BundleEntityDataApi[]>([]);
  const [value, setValue] = useState('');

  // @ts-ignore
  Search.handleClickOutside = () => setList([]);

  return (
    <label className={`${styles.label} ${styles.search}`}>
      <Field
        className={styles.field}
        type={'text'}
        value={value}
        placeholder={'search solution by vendor'}
        autoComplete={'off'}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setValue(event.target.value);

          const val = event.target.value.toLowerCase();

          const items: BundleEntityDataApi[] = [];

          for (const vendor of vendors) {
            if (items.length >= 5) {
              break;
            }

            if (!vendor.name.toLowerCase().includes(val)) {
              continue;
            }

            const item = {
              name: vendor.name,
              image: vendor.image,
            };

            items.push({
              role: '',
              ...item
            });
          }

          // tslint:disable-next-line:prefer-for-of
          for (const proptech of propteches) {
            if (items.length >= 5) {
              break;
            }

            if (!proptech.name.toLowerCase().includes(val)) {
              continue;
            }

            items.push({
              role: '',
              ...proptech
            });
          }

          setList(items);
        }}
        onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
          if (event.key !== 'Enter') {
            return;
          }

          event.preventDefault();

          if (!list.length) {
            return;
          }

          const item = {
            ...list[0]
          };

          props.arrayHelpers.push(item);

          setList([]);
          setValue('');
        }}
        tabIndex={0}
      />

      <div className={styles.dropdown}>
        {list.map((item, i) => (
          <div
            key={i}
            className={styles.dropdownItem}
            onClick={() => {
              props.arrayHelpers.push(item);

              setList([]);
              setValue('');
            }}
          >
            <div
              className={styles.dropdownImage}
              style={{
                // tslint:disable-next-line:max-line-length
                backgroundImage: `url(${process.env.PUBLIC_URL}/images/vendors/${item.image})`
              }}
            />

            <div className={styles.dropdownName}>
              {item.name}
            </div>
          </div>
        ))}
      </div>
    </label>
  );
};


const SearchWithClickOutside = onClickOutside(Search, {
  // @ts-ignore
  handleClickOutside: () => Search.handleClickOutside
});

const ErrorMessage = ({ name }: { name: string }) => {
  return (
    <div className={styles.error}>
      <Field
        name={name}
        render={({ form }: { form: any }) => {
          const error = getIn(form.errors, name);
          const touch = getIn(form.touched, name);
          return form.submitCount && touch && error ? error : null;
        }}
      />
    </div>
  );
};

const Api: FunctionComponent<{
  arrayHelpers: FieldArrayRenderProps,
  api: any,
  index: number,
}> = (props) => {
  return (
    <div className={styles.api}>
      <div
        className={styles.image}
        style={{
          // tslint:disable-next-line:max-line-length
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/vendors/${props.api.image})`
        }}
      />

      <div className={styles.content}>
        <div className={styles.name} title={props.api.name}>
          {props.api.name}
        </div>

        <label className={`${styles.label} ${styles.role}`}>
          <ErrorMessage name={`apis.${props.index}.role`}/>

          <Field
            className={styles.field}
            name={`apis.${props.index}.role`}
            placeholder={'solution role'}
          />
        </label>

        <div className={styles.button}>
          <Button
            size={'small'}
            color={'red'}
            onClick={(event: MouseEvent) => {
              event.preventDefault();
              props.arrayHelpers.remove(props.index);
            }}
          >
            Remove
          </Button>
        </div>
      </div>

      <Field
        className={styles.field}
        type={'hidden'}
        name={`apis.${props.index}.name`}
        placeholder={'name'}
      />

      <Field
        className={styles.field}
        type={'hidden'}
        name={`apis.${props.index}.image`}
        placeholder={'image'}
      />
    </div>
  );
};

const Apis: FunctionComponent<FieldArrayRenderProps> = (arrayHelpers) => {
  const values = arrayHelpers.form.values;

  const ArrayErrors = ({ errors }: { errors: FormikErrors<any> }) => {
    return typeof errors.apis === 'string' ? (
      <div className={styles.error}>{errors.apis}</div>
    ) : null;
  };

  return (
    <>
      <SearchWithClickOutside
        arrayHelpers={arrayHelpers}
      />

      <div className={styles.apis}>
        <div className={styles.title}>
          Vendors
        </div>

        <ArrayErrors
          errors={arrayHelpers.form.errors}
        />

        {values.apis.map((api: any, index: number) => (
          <Api
            key={index}
            arrayHelpers={arrayHelpers}
            api={api}
            index={index}
          />
        ))}
      </div>
    </>
  );
};

const Bundle = (props: FormikProps<BundleFormValues>) => {
  const handleClick = (api: BundleEntityDataApi) => {
    props.values.apis.push(api);

    props.setValues(props.values);
  };

  return (
    <>
      <Form autoComplete={'off'} className={styles.form}>
        <FieldArray
          name={'apis'}
          render={Apis}
        />

        <div className={styles.block}>
          <div className={styles.title}>
            Information
          </div>

          {/* Name */}
          <label className={styles.label}>
            <ErrorMessage name={'name'}/>

            <Field
              className={styles.field}
              name='name'
              placeholder={'name'}
              autoComplete={'off'}
            />
          </label>

          {/* Description */}
          <label className={styles.label}>
            <ErrorMessage name={'description'}/>

            <Field
              className={`${styles.field} ${styles.textarea}`}
              component={'textarea'}
              name='description'
              placeholder={'description'}
              autoComplete={'off'}
            />
          </label>
        </div>

        <div className={styles.block}>
          <div className={styles.title}>
            Applicability
          </div>

          {/* Domain */}
          <label className={styles.label}>
            <ErrorMessage name={'domain'}/>

            <Field
              className={styles.field}
              component='select'
              name='domain'
              defalutvalue={''}
            >
              <option
                value={''}
                disabled={true}
              >
                Select solution domain
              </option>

              {domains.map((it, i) => (
                <option key={i} value={it}>{it}</option>
              ))}
            </Field>
          </label>

          {/* Vertical */}
          {props.values.domain && (
            <label className={styles.label}>
              <ErrorMessage name={'vertical'}/>

              <Field
                className={styles.field}
                component='select'
                name='vertical'
                defalutvalue={''}
              >
                <option
                  value={''}
                  disabled={true}
                >
                  Select solution vertical
                </option>

                {/*
                  //@ts-ignore */}
                {vectors[props.values.domain].map((it, i) => (
                  <option key={i} value={it}>{it}</option>
                ))}
              </Field>
            </label>
          )}
        </div>

        <div className={styles.block}>
          <div className={styles.title}>
            Impact
          </div>

          {/* KPI */}
          <label className={styles.label}>
            <ErrorMessage name={'kpi'}/>

            <Field
              className={styles.field}
              component='select'
              name='kpi'
              defalutvalue={''}
            >
              <option
                value={''}
                disabled={true}
              >
                select KPI
              </option>

              {kpi.map((it, i) => (
                <option key={i} value={it}>{it}</option>
              ))}
            </Field>
          </label>

          {/* ROI */}
          <label className={styles.label}>
            <ErrorMessage name={'roi'}/>

            <Field
              className={styles.field}
              component='select'
              name='roi'
              defalutvalue={''}
            >
              <option
                value={''}
                disabled={true}
              >
                select ROI
              </option>

              {roi.map((it, i) => (
                <option key={i} value={it}>{it}</option>
              ))}
            </Field>
          </label>
        </div>

        <div className={styles.buttons}>
          <div className={styles.button}>
            <Button
              size={'small'}
              onClick={(event) => {
                event.preventDefault();

                props.resetForm({
                  name: '',
                  description: '',
                  kpi: '',
                  roi: '',
                  domain: '',
                  vertical: '',
                  apis: []
                });
              }}
            >
              Reset All
            </Button>
          </div>

          <div className={styles.button}>
            <Button
              size={'small'}
              color={'red'}
            >
              Create bundle
            </Button>
          </div>
        </div>
      </Form>

      <Vendors
        handleClick={handleClick}
      />
    </>
  );
};


type BundleFormProps = {
  vendor?: BundleEntityDataApi
};


class BundleForm extends Component<BundleFormProps> {

  onSubmit = async (
    values: BundleFormValues,
    actions: FormikActions<BundleFormValues>
  ): Promise<void> => {
    try {
      await BundleEntity.create(values);

      actions.resetForm({
        name: '',
        description: '',
        kpi: '',
        roi: '',
        domain: '',
        vertical: '',
        apis: []
      });

      toast.success('Bundle created!');
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <Formik
        component={Bundle}
        initialValues={initialValues}
        onSubmit={this.onSubmit}
        validationSchema={validationSchema}
      />
    );
  }
}

export {
  BundleForm
};

import description from './description';
import useCases from './use-cases';

export const twentyOneSTSmartDataProductsApi = {
  uuid: '7c7b6cb8-755e-4a16-82f2-16afe2b9c4a0',
  name: '21st Smart Data Products Api',
  category: 'Property market',
  url: '21st-smart-data-products-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: '21st Real Estate',
};

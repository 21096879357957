/** Imports */
import React, { Component } from 'react';

import {
  observable
} from 'mobx';

import {
  observer
} from 'mobx-react';

import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';

import {
  toast
} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import {
  Header,
  Loading,
  Main,
  ScrollToTop,
} from 'app/components';

import {
  SessionContext
} from 'app/contexts';

import {
  SessionEntity,
} from 'app/entities';

import {
  ApiPage,
  BundlesPage,
  IndexPage,
} from 'app/pages';

import './root.scss';


/** Init */
toast.configure({
  autoClose: 3000,
  position: 'bottom-right',
});


@observer
class Root extends Component {
  @observable
  session?: SessionEntity;

  @observable
  isLoading: boolean = true;

  _url = `${process.env.REACT_APP_BACKEND_URL}/api/v1/session`;

  componentDidMount(): void {
    fetch(this._url, {
      method: 'GET',
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res.data) {
          return;
        }

        this.session = new SessionEntity(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => this.isLoading = false);
  }

  public render() {
    return (
      <BrowserRouter>
        <ScrollToTop/>

        {this.isLoading && (
          <Loading/>
        )}

        {!this.isLoading && (
          <SessionContext.Provider value={this.session}>
            <Header/>

            <Switch>
              <Main>
                <Route
                  path='/'
                  exact={true}
                  component={IndexPage}
                />

                <Route
                  path='/apis/:apiUrl'
                  component={ApiPage}
                />

                <Route
                  path='/bundles'
                  component={BundlesPage}
                />
              </Main>
            </Switch>
          </SessionContext.Provider>
        )}
      </BrowserRouter>
    );
  }
}


export {
  Root
};

import description from './description';
import useCases from './use-cases';

export const platformOfTrustCalendarApi = {
  uuid: '8a7d3a4d-3a23-4ce5-9e3c-add1cab66739',
  name: 'POT Calendar Api',
  category: 'Property market',
  url: 'platform-of-trust-calendar-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: 'Platform Of Trust',
};

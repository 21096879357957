/** Imports */
import React, {
  Component,
} from 'react';

import styles from './loading.module.scss';


class Loading extends Component {
  render() {
    return (
      <div className={styles.loading}>
        <div className={styles.icon}/>
      </div>
    );
  }
}

export {
  Loading
};

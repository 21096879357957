import description from './description';
import useCases from './use-cases';

export const sensorbergBackendApi = {
  uuid: '16e53275-c027-4009-8be1-fe7371ad43a4',
  name: 'Sensorberg Backend API',
  category: 'Property market',
  url: 'sensorberg-backend-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: true,
  vendorName: 'Sensorberg',
};

/** Imports */
import React, {
  FunctionComponent,
  ReactNode,
} from 'react';

import { NavLink } from 'react-router-dom';

import styles from './tab.module.scss';


/** Types */
type Props = {
  to: string;
  children: ReactNode;
};

const Tab: FunctionComponent<Props> = (props) => {
  return (
    <NavLink
      to={props.to}
      className={styles.tab}
      activeClassName={styles.active}
    >
      {props.children}
    </NavLink>

    /*<div className={styles.tabs}>
      {props.children}
    </div>*/
  );
};

export {
  Tab
};

// tslint:disable:max-line-length
const useCases = `
Full insight into your property asset portfolios\n

Portfolio and asset management\n

Get one integrated view to portfolios, properties, and rent roll\n
Automated financial reporting\n

Streamline your property asset management processes for more efficient decision-making\n
Visualized dashboards\n

Know the financial performance of your assets at a glance\n
Team collaboration
`;
// tslint:enable:max-line-length

export default useCases;

import description from './description';
import useCases from './use-cases';

export const redfiveApi = {
  uuid: '7286a036-7d04-4b87-afc1-7d6b2a23a32d',
  name: 'Red5 Api',
  category: 'Property market',
  url: 'redfive-api',
  description: description,
  useCases: useCases,
  swagger: require('./openapi.json'),
  locked: false,
  vendorName: 'REDFIVE',
};

// tslint:disable:max-line-length
const description = `
# docunite | The first Real Estate DMS

docunite(r) is the first DMS for real estate professionals.

Imagine having an industry-leading partner at your side who takes over the complete management of your real estate documents. This is dounite(r)! We seamlessly integrate best practices from the real estate industry into your processes and offer you ongoing, standardized development of our platform by using the latest technologies and AI.
`;
// tslint:enable:max-line-length

export default description;

/** Imports */
import React, {
  FunctionComponent,
} from 'react';

import styles from './hero.module.scss';


/** Types */
type Props = {
  titleLight: string;
  titleBold: string;
  description: string
};


const Hero: FunctionComponent<Props> = (props) => {


  return (
    <>
      <div className={styles.bg}/>

      <div className={styles.hero}>
        <div className={styles.title}>
          <div className={styles.light}>
            {props.titleLight}
          </div>

          <div className={styles.bold}>
            {props.titleBold}
          </div>
        </div>

        <div className={styles.description}>
          {props.description}
        </div>
      </div>
    </>
  );
};

export {
  Hero
};
